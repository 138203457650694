// Import Packages
import React from "react"
import { Link } from "gatsby"

// Import Assets
import Logo from "../images/logo.svg"

function Header(props) {
  return(
    <div id="header">
      <div className="clearfix">
        <div className="logo">
          <Link to="/"><img src={Logo} alt="LOGO" height="52" width="362"/></Link>
        </div>
        <ul className="navigation">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/about/">About</Link>
          </li>
          <li>
            <Link to="/practices/">Practices</Link>
          </li>
          {/* <!-- <li className="active">
            <Link to="lawyers">Our Lawyers</a>
            </li>
            <li>
            <Link to="news">News</a>
            <div>
            <Link to="singlepost">News Single Post</a>
            </div>
          </li> --> */}
          <li>
            <Link to="/contact/">Contact</Link>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Header;