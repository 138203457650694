import React from "react"
import { Link } from "gatsby"

function Footer(props) {
  return(
    <div id="footer">
      <div className="clearfix">
        <div className="section contact">
          <div className="left">
            <h4>Contact Us</h4>
            <p>
              <span>Address:</span> 100 N Stone #601, Tucson, AZ 85701
            </p>
            <p>
              <span>Phone:</span> +1 520-591-7919 
            </p>
            <p>
              <span>Email:</span> drizzi@rizzi-law.com
            </p>
          </div>
        </div>
        {/* <div className="section">
          <h4>Latest News</h4>
          <p>
          </p>
        </div> */}
        <div className="section">
          <div className="right">
            <h4>SEND US A MESSAGE</h4>
            <p>
              Want us to represent you?
            </p>
            <Link to="/contact/" className="subscribe">Click to send us an email</Link>
          </div>
        </div>
      </div>
      <div id="footnote">
        <div className="clearfix">
          {/* <div className="connect">
            <a href="" className="facebook"></a><a href="" className="twitter"></a><a href="" className="googleplus"></a><a href="" className="pinterest"></a>
          </div> */}
          <div id="textbox">
            <p>© Copyright 2021 The Law Office of Dominic Rizzi PLLC. All Rights Reserved.</p>
            <p className="designer">Website designed by <a href="https://www.linkedin.com/services/page/368034310b851a8763" target="_blank" rel="noreferrer">Isaiah Evans</a></p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer;