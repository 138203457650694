import React from "react"
import { Helmet } from "react-helmet"

function PageTitle(props) {
  const pageTitle = "The Law Office of Dominic Rizzi"
  return(
    <Helmet>
      <title>{ props.pageTitle + pageTitle }</title>
    </Helmet>
  )
}

export default PageTitle;